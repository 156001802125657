import React from 'react'
import HeroSection from '../../components/organisms/HeroSection'

export default function HomePage() {
  return (
    <div>
        <HeroSection />
    </div>
  )
}
