import React from 'react'
import HomePage from './pages/HomePage/HomePage'

export default function App() {
  return (
    <div>
        <HomePage />
    </div>
  )
}
