import React from "react";

export default function HeroSection() {
  return (
    <div
      className="h-screen bg-cover bg-center bg-no-repeat flex flex-col justify-end"
      style={{
        backgroundImage: `url('https://media.discordapp.net/attachments/1088424747680935977/1142477509036871830/gavindean_a_landscape_with_an_ambient_subtle_soft_gorgeous_mil_f0ec61ee-4ebe-48f2-b94c-93f471ed74bf.png?width=2160&height=1234')`,
      }}
    >
      <div className="flex items-end text-white text-center mt-auto">
        <div className="backdrop-blur-sm p-10 rounded-lg bg-orange-950/50">
          <h1 className="text-3xl font-bold mb-2">Bitcoin Beehive</h1>
          <h2 className="text-xl font-medium text-white/80 mb-2">Where honey becomes money</h2>
          <p className="text-sm">
            This is just a placeholder.
            Eventually this website will be the home
            for all things bitcoin and digital assets
          </p>
        </div>
      </div>
    </div>
  );
}